import { Col, Container, Row } from 'react-bootstrap'

import BlockPanel from '../components/HomePage/BlockPanel'
import { Link } from 'gatsby'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import ReactMarkdown from 'react-markdown'
import { SRLWrapper } from 'simple-react-lightbox'
import ShopsHeader from '../components/Shops/ShopsHeader'
import { createUseStyles } from 'react-jss'
import { getImage } from '../helpers/image'
import { useTranslation } from 'react-i18next'

export const useStyles = createUseStyles((theme) => ({
  blockPanel: {
    maxWidth: '1160px',
    margin: 'auto',
    marginTop: '137px',
  },
  breadCrumb: {
    fontSize: 11,
    color: theme.colors.darkGrey,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    margin: '0 0 60px 0',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.primaryGreen,
    },
    '& span': {
      marginRight: 10,
    },
    '& i': {
      transform: 'rotate(180deg)',
      '&:before': {
        color: theme.colors.primaryGreen,
      },
    },
  },
  articleBreadcrumb: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.5px',
    color: theme.colors.darkGrey,
    marginBottom: 20,
  },
  productClassList: {
    listStyleType: 'none',
    display: 'inline-block',
    margin: '0 0 0 15px',
    padding: 0,
    '& li': {
      display: 'inline-block',
      padding: '0 15px',
      textTransform: 'uppercase',
      borderLeft: `1px solid ${theme.colors.lightGrey}`,
      color: theme.colors.darkGrey,
      fontSize: 11,
    },
  },
  content: {
    '& p': {
      color: theme.colors.darkGrey,
      fontSize: '16px',
      letterSpacing: '0.67px',
      fontWeight: 200,
      position: 'relative',
      '& img': {
        maxWidth: '100%',
      },
    },
    '& .media *': {
      width: '100%',
    },
  },
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-10px',
    width: 'calc(100% + 20px)',
  },
  galleryItem: {
    position: 'relative',
    flex: '0 0 calc(33.3333333% - 20px)',
    backgroundSize: 'cover',
    height: 0,
    paddingBottom: 'calc(33.33333% - 20px)',
    margin: '10px',
    borderRadius: '10px',
    '& img': {
      width: '100%',
      height: 'auto',
      opacity: 0,
    },
  },
  articleImage: {
    margin: '15px 0',
    '& img': {
      width: '100%',
    },
  },
}))

type BlogProps = {
  blogPost: any
  alternativeArticles: any
  url: string
}

const Blog: React.ComponentType<BlogProps> = ({
  blogPost,
  alternativeArticles,
  url,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const data = alternativeArticles ? alternativeArticles.data : null

  let blogContent = null
  if (blogPost) {
    const isOldContentEditor =
      new Date('2021-01-01') > new Date(blogPost.updated_at)
    blogContent = isOldContentEditor ? (
      <ReactMarkdown source={blogPost.Content} />
    ) : (
      <div>{ReactHtmlParser(blogPost.Content)}</div>
    )
  }

  return (
    <>
      {blogPost && (
        <ShopsHeader
          MetaDescription={blogPost.MetaDescription}
          MetaTitle={blogPost.MetaTitle}
          image={blogPost.CoverPhoto?.url}
          shareButtons={true}
          title={blogPost.Title}
          url={url}
        />
      )}

      <Container>
        <Row>
          <Col>
            <Link to="/blog" className={classes.breadCrumb}>
              <span>
                <i className="icon-arrow-left"></i>
              </span>
              {t('BACK_TO_LISTING')}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={{ span: 8, offset: 2 }}>
            <div className={classes.articleBreadcrumb}>
              <span>
                {blogPost &&
                  !blogPost.Categories[0].HidePublicDate &&
                  new Date(blogPost.PublicationDate).toLocaleString('cz-CS', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  })}
              </span>
              <ul className={classes.productClassList}>
                {blogPost &&
                  blogPost.Tags.map((tag) => (
                    <li key={tag.Name}>{tag.Name}</li>
                  ))}
              </ul>
            </div>
            {blogPost && (
              <div className={classes.articleImage}>
                <img
                  src={getImage(blogPost.CoverPhoto, 'large')}
                  alt={blogPost.Title}
                />
              </div>
            )}
            <div>
              <div className={classes.content}>{blogPost && blogContent}</div>
            </div>
            <SRLWrapper>
              <div className={classes.gallery}>
                {blogPost &&
                  blogPost.Gallery.map((item, index) => (
                    <a
                      key={item?.id || index}
                      className={classes.galleryItem}
                      href={getImage(item, 'large')}
                      style={{
                        backgroundImage: `url(${getImage(item, 'small')})`,
                      }}
                    >
                      <img
                        src={getImage(item, 'large')}
                        alt={blogPost.Title}
                      ></img>
                    </a>
                  ))}
              </div>
            </SRLWrapper>
          </Col>
        </Row>
      </Container>
      <section className={classes.blockPanel}>
        {data && data.cms && data.cms['blogPosts'] && (
          <BlockPanel blockPosts={data.cms['blogPosts']} />
        )}
      </section>
    </>
  )
}

export default Blog
